import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.message_base_url)


// 消息服务 - 接口列表
const apis = {
  // 发送企业用户验证码
  sendEnterpriseUserVerificationCode: '/sms/sendEnterpriseUserVerificationCode',
}


// 发送企业用户验证码
export function sendEnterpriseUserVerificationCode (parameter) {
  return request({
    url: apis.sendEnterpriseUserVerificationCode,
    method: 'post',
    data: parameter
  })
}

