<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" v-if="!detailData.certificateNumber" class="mr-16" @click="issueCertificateHandle"><i class="e-icon award"></i>颁发证书</a-button>
      <a-button size="small" v-else class="mr-16" @click="viewCertificateHandle"><i class="e-icon award"></i>查看证书</a-button>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <a-spin :spinning="loading1">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">基础信息</h3>
          <div class="mod-handles">
              <a-button v-if="!companyEditable" type="link" size="small" @click="editBasicInfoHandle"
              ><i class="e-icon edit-blue"></i>编辑</a-button>
              <template v-else>
                <a-button type="link" size="small" @click="saveBasicInfo">保存</a-button>
                <a-button type="link" size="small" class="grey ml-8" @click="companyEditable = false">取消</a-button>
              </template>
            </div>
        </div>
        <div class="module-main bdm" style="padding-bottom: 16px;" v-if="!companyEditable">
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业名称：</span>
                <span class="detail-value">{{detailData.companyName || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业类型：</span>
                <span class="detail-value">{{detailData.companyTypeText}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照：</span>
                <span class="detail-value">{{detailData.companyLicenseNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照图片：</span>
                <a-button v-if="detailData.companyLicenseImageUrl" type="link" style="padding: 0" @click="viewBigImage(1)">点击查看营业执照照片</a-button>
                <span class="detail-value" v-else>-</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人姓名：</span>
                <span class="detail-value">{{detailData.companyChargePerson || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人身份号码：</span>
                <span class="detail-value">{{detailData.companyChargePersonIdNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">公司地址：</span>
                <span class="detail-value">{{detailData.companyFullAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">工厂地址：</span>
                <span class="detail-value">{{detailData.factoryFullAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系人员：</span>
                <span class="detail-value">{{detailData.companyContactPerson || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系电话：</span>
                <span class="detail-value">{{detailData.companyContactPhone || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="16">
              <div class="detail-item">
                <span class="detail-label">业务类型：</span>
                <span class="detail-value">{{businessTypesStr}}</span>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="module-main bdm" v-if="companyEditable">
          <a-form-model
            ref="editForm"
            :model="editForm"
            :rules="editRules"
            layout="vertical"
          > 
            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="企业名称" prop="companyName">
                  <a-input style="width: 80%" v-model="editForm.companyName" placeholder="请输入企业名称" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="企业类型" prop="companyType">
                  <a-radio-group v-model="editForm.companyType">
                    <a-radio :value="1">
                      工商型
                    </a-radio>
                    <a-radio :value="2">
                      个人型
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8" v-if="editForm.companyType == 1">
              <a-col :span="12">
                <a-form-model-item label="营业执照号码" prop="companyLicenseNumber">
                  <a-input style="width: 80%" v-model="editForm.companyLicenseNumber" placeholder="请输入营业执照号码" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="营业执照照片" required>
                  <span style="cursor:pointer; color: #666; margin-right: 16px;" v-if="editForm.companyLicenseImageUrl" @click="viewBigImage(2)">点击查看营业执照照片</span>
                  <file-upload
                    name="files"
                    :multiple="false"
                    :data="uploadData"
                    :action="uploadFileApi"
                    :show-upload-list="false"
                    @change="handleUploadChange"
                    accept="image/*"
                    :limit="{
                      type: {
                        reg: /^image\/.*/,
                        msg: '请上传图片格式的图片'
                      },
                      size: {
                        max: 20,
                        msg: '图片的大小不能超过20M'
                      }
                    }"
                    :custom-before-upload="customBeforeUpload"
                  >
                    <a-button type="link" style="padding: 0">{{editForm.companyLicenseImageUrl ? '重新上传': '点击上传'}}</a-button>
                  </file-upload>
                  <!-- <img src="https://api.minio.dev.ysintelligent.com/yijiajia-company-license/2021/07/14/a54709e76bae9a2a269ca86b3012067b.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=Q6OYL97O80V8FKTIET78%2F20210714%2Fcnc-dev%2Fs3%2Faws4_request&X-Amz-Date=20210714T064251Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Signature=148aae6046521d472b9c71f0763eae99325f00b0187c7f92e8127f7864e09d5c" alt=""> -->
                  <!-- <a-button type="link" @click="$message.info('图片上传功能开发中...')">重新上传</a-button> -->
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="负责人姓名" prop="companyChargePerson">
                  <a-input style="width: 80%" v-model="editForm.companyChargePerson" placeholder="请输入负责人姓名" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="负责人身份证号" prop="companyChargePersonIdNumber"> 
                  <a-input style="width: 80%" v-model="editForm.companyChargePersonIdNumber" placeholder="请输入负责人身份证号" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="公司地址" prop="companyAddress">
                  <a-cascader style="width: 30%;" class="address-cascader"
                    :field-names="{ label: 'region_name', value: 'region_id', children: 'children' }"
                    :options="addressOptions"
                    :load-data="loadAddressData"
                    change-on-select 
                    placeholder="省/市/区(县)"
                    @change="onAddressChange"
                    :getPopupContainer="node => node.parentNode || document.body"
                    v-model="editForm.companyRegions"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" 
                    v-model="editForm.companyAddress"
                    placeholder="请输入公司地址"
                    @blur="() => {
                      $refs['editForm'].validateField('companyAddress')
                    }"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="工厂地址（选填）">
                  <a-cascader style="width: 30%;" class="address-cascader" 
                    :field-names="{ label: 'region_name', value: 'region_id', children: 'children' }"
                    :options="addressOptions"
                    :load-data="loadAddressData"
                    change-on-select 
                    placeholder="省/市/区(县)"
                    @change="onAddressChange"
                    :getPopupContainer="node => node.parentNode || document.body"
                    v-model="editForm.factoryRegions"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" v-model="editForm.factoryAddress" placeholder="请输入公司地址" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="联系人员" prop="companyContactPerson">
                  <a-input style="width: 80%" v-model="editForm.companyContactPerson" placeholder="请输入联系人员" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系电话" prop="companyContactPhone">
                  <a-input style="width: 80%" v-model="editForm.companyContactPhone" placeholder="请输入联系电话" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="8">
              <a-col :span="24">
                <a-form-model-item label="业务类型（选填）">
                  <a-checkbox-group v-model="editForm.businessTypes">
                    <a-checkbox :value="item.id" name="businessType" v-for="item in templateList" :key="item.id">
                      {{item.name}}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-spin>

    <a-spin :spinning="loading2">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">业务信息</h3>
          <div class="mod-handles">
              <a-button v-if="!businessEditable" type="link" size="small" @click="editBusinessHandle"
              ><i class="e-icon edit-blue"></i>编辑</a-button>
              <template v-else>
                <a-button type="link" size="small" @click="editBusinessOk">保存</a-button>
                <a-button type="link" size="small" class="grey ml-8" @click="businessEditable = false">取消</a-button>
              </template>
            </div>
        </div>
        <div class="module-main bdm" v-if="!businessEditable" style="padding-bottom: 32px;">
          <!-- 企业无业务信息时展示 -->
          <div class="no-business" v-if="selectedBusinessTypes.length == 0">
            <a-empty :image="simpleImage" description="当前企业未选择业务类型" />
          </div>
          <!-- 企业有业务信息时展示 -->
          <div class="business-show-wrap"  v-else>
            <a-radio-group v-model="currentBusinessId" button-style="solid" @change="businessChange">
              <a-radio-button :value="business.id" v-for="business in selectedBusinessTypes" :key="business.id">
                {{business.name}}
              </a-radio-button>
            </a-radio-group>

            <div class="business-show">
              <div class="business-item" v-show="currentBusinessId === business.id" v-for="business in selectedBusinessTypes" :key="business.id">
                <!-- <div style="padding: 32px 0 0;" v-if="!business.hasProps">
                  <a-empty :image="simpleImage" description="当前业务类型无相关属性" />
                </div> -->
                <div style="padding: 32px 0 0;" v-if="!business.isEdited">
                  <!-- <span class="detail-label">当前企业未编辑相关属性</span> -->
                  <a-empty :image="simpleImage" description="当前企业未编辑相关属性" />
                </div>
                <div style="padding: 32px 0 0;" v-else>
                  <div class="detail-item" v-for="cont in business.content" :key="cont.id">
                    <span class="detail-label">{{cont.key}}：</span>
                    <span class="detail-value">{{cont.showText}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="module-main bdm" v-else style="padding-bottom: 32px;">
          <!-- 企业无业务信息时展示 -->
          <div class="no-business" v-if="businessTypes.length == 0">
            <a-empty :image="simpleImage" description="当前企业未选择业务类型" />
          </div>
          <!-- 企业有业务信息时展示 -->
          <div class="business-show-wrap" v-else>
            <a-radio-group v-model="currentEditBusinessId" button-style="solid" @change="businessEditChange">
              <a-radio-button :value="business.id" v-for="business in businessTypes" :key="business.id"
                :style="[currentEditBusinessId != business.id && business.isEdited ? {color: '#0267ED'} : null]"
              >
                {{business.name}}
              </a-radio-button>
            </a-radio-group>

            <div class="business-show">
              <div class="business-item" v-show="currentEditBusinessId === business.id" v-for="business in businessTypes" :key="business.id">
                
                <div style="padding: 32px 0 0;" v-if="!business.isEdited">
                  <!-- <span class="detail-label">当前企业未编辑相关属性</span> -->
                  <a-empty :image="simpleImage" description="当前企业未编辑相关属性" />
                </div>
                <div style="padding: 32px 0 0;" v-else-if="!business.hasProps">
                  <!-- <span class="detail-label">当前业务类型无相关属性</span> -->
                  <a-empty :image="simpleImage" description="当前业务类型无相关属性" />
                </div>
                <div style="padding: 32px 0 0;" v-else>
                  <a-form-model :label-col="{span: 3}" :wrapper-col="{span: 18}" labelAlign="left">
                    <a-form-model-item :label="cont.key" v-for="cont in business.content" :key="cont.id">
                      <custom-form-item :form-type="cont.type" :value.sync="cont.value" :range="cont.data" :unit="cont.unit"/>
                    </a-form-model-item>
                  </a-form-model>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

    <a-spin :spinning="loading3">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">企业用户</h3>
          <div class="mod-handles">
              <a-button type="link" size="small" @click="addUservisible = true"
              ><i class="e-icon add-user"></i>新增用户</a-button>
              <a-button type="text" size="small" class="grey ml-8" @click="bindUservisible = true"
              ><i class="e-icon connect"></i>关联用户</a-button>
            </div>
        </div>
        <div class="module-main" v-if="!companyEditable">
          <!-- 企业无业务信息时展示 -->
          <div class="no-business" v-if="userData.length == 0">
            <a-empty :image="simpleImage" description="暂无企业用户" />
          </div>
          <div style="width: 90%" v-if="userData.length > 0">
            <a-table :columns="userColumns" :data-source="userData" rowKey="userId" bordered :pagination="false">
              <template slot="userStatus" slot-scope="text">
                <a-badge v-if="text == 'y'" color="green" text="有效" />
                <a-badge v-if="text == 'n'" color="#999" text="无效" />
              </template>
              <div slot="handles" slot-scope="text, record">
                <a-button type="link" size="small" v-if="record.userStatus == 'n'" @click="updateCompanyUser('y', record)">启用</a-button>
                <a-button type="link" size="small" v-if="record.userStatus == 'y'"  @click="updateCompanyUser('n', record)">禁用</a-button>
                <a-button type="link" size="small" @click="deleteCompanyUser(record)">删除</a-button>
              </div>
            </a-table>
          </div>
        </div>
      </div>
    </a-spin>
    <!-- 查看大图 -->
    <a-modal v-model="imgVisible" title="营业执照照片(以下为演示图片)" centered :width="800" :footer="null">
      <img style="width: 100%;" :src="viewImgUrl">
    </a-modal>

    <!-- 查看会员证书 -->
    <a-modal v-model="certificateVisible" title="证书照片" centered :width="806" :footer="null">
      <div class="certificate-img-box">
        <span class="title">{{detailData.companyName}}</span>
        <span class="code">No:{{detailData.certificateNumber}}</span>
      </div>
    </a-modal>

    <a-modal v-model="addUservisible" title="新增企业用户" centered :maskClosable="false" @ok="addUserOk">
      <a-form-model
        ref="addUserForm"
        :model="addUserForm"
        :rules="addUserRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="用户昵称" prop="userNickName">
          <a-input v-model="addUserForm.userNickName" placeholder="请输入用户昵称" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="userPhone">
          <a-input v-model="addUserForm.userPhone" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="phoneCode">
          <a-input style="width: 68%;" v-model="addUserForm.phoneCode" placeholder="请输入验证码" />
          <a-button style="width: 30%; margin-left: 2%"
          @click="getSmsCode1"
          :disabled="smsCaptcha1.isWaiting"
          >{{smsCaptcha1.showText}}</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="bindUservisible" title="关联企业用户" centered :maskClosable="false" @ok="bindUserOk">
      <a-form-model
        ref="bindUserForm"
        :model="bindUserForm"
        :rules="bindUserRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="手机号" prop="userPhone">
          <a-input v-model="bindUserForm.userPhone" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="phoneCode">
          <a-input style="width: 68%;" v-model="bindUserForm.phoneCode" placeholder="请输入验证码" />
          <a-button style="width: 30%; margin-left: 2%"
            @click="getSmsCode2"
            :disabled="smsCaptcha2.isWaiting"
          >{{smsCaptcha2.showText}}</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { Empty } from 'ant-design-vue'
import CustomFormItem from '@/components/CustomFormItem.vue'
import { 
  approvedCompanyDetail,
  issueCertificate,
  updateCompanyDetail,
  enterpriseUserList,
  saveEnterpriseUser,
  bindRelationship,
  dismissRelationship
} from '@/api/company'
import { templateList, businessList, submitBusiness, propertyList } from '@/api/system'
import { sendEnterpriseUserVerificationCode } from '@/api/message'
import { updateState } from "@/api/user"
import { regionInfo } from '@/api/address'
import { uploadFileApi } from '@/api/upload'
import { relatedApps } from '../../user/user-config'
import { validateMobile, validateIdcard } from '@/utils/validate'
import { mapGetters } from 'vuex'
import { buildThumborUrl } from '@/utils/tools'
import FileUpload from '@/components/FileUpload.vue' 
const userColumns = [
  {
    title: '用户昵称(姓名)',
    align: 'center',
    dataIndex: 'userNickname'
  },
  {
    title: '联系电话',
    align: 'center',
    dataIndex: 'userCellphone',
  },
  {
    title: '关联应用',
    align: 'center',
    dataIndex: 'associatedApplications',
    ellipsis: true
  },
  {
    title: '状态',
    align: 'center',
    dataIndex: 'userStatus',
    scopedSlots: { customRender: 'userStatus' },
  },
  {
    title: '相关操作',
    align: 'center',
    scopedSlots: { customRender: 'handles' },
  },
]
export default {
  name: 'static-data-company-maintenance-detail',
  keep_alive_tab: true,
  components: {
    CustomFormItem,
    FileUpload
  },
  data() {
        let validPhone = (rule, value, callback) => {
      // console.log(rule, value, callback)
      value = value.trim()
      if (value === '') {
          callback(new Error('请输入手机号'))
      } else if (!validateMobile(value)) {
          callback(new Error('手机号格式不正确'))
      } else {
          callback()
      }
    }
    let validIdcard = (rule, value, callback) => {
      // console.log(rule, value, callback)
      value = value.trim()
      if (value === '') {
          callback(new Error('请输入身份证号码'))
      } else if (!validateIdcard(value)) {
          callback(new Error('身份证号码格式不正确'))
      } else {
          callback()
      }
    }

    let validCompanyAddress = (rule, value, callback) => {
      if (this.editForm.companyRegions.length<=0) {
        callback(new Error('请选择区域'))
      } else if (this.editForm.companyAddress.trim() == '') {
        callback(new Error('请填写详细地址'))
      } else {
        callback()
      }
    }
    return {
      // 当前id 和 路由
      id: '',
      currentPath: '',

      // 空状态图片类型
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      uploadFileApi,
      uploadData: {
        bucketName: 'yijiajia-company-license'
      },

      viewImgUrl: '',
      
      // 基础信息编辑
      companyEditable: false,
      businessEditable: false,

      loading1: false,
      loading2: false,
      loading3: false,
      imgVisible: false,

      detailData: {
        companyStatus: '',

        companyName: '',
        companyType: '',
        companyTypeText: '',

        companyLicenseNumber: '',
        companyLicenseImage: '',
        companyLicenseImageUrl: '',
        
        companyChargePerson: '',
        companyChargePersonIdNumber: '',


        companyFullAddress: '',
        companyProvince: '',
        companyCity: '',
        companyDistrict: '',
        companyAddress: '',
        
        companyContactPerson: '',
        companyContactPhone: '',

        factoryFullAddress: '',
        factoryProvince: '',
        factoryCity: '',
        factoryDistrict: '',
        factoryAddress: '',

        businessTypes: [],
      },
      businessTypesArr: [],
      businessTypesStr: '',

      editForm: {
        companyName: '',
        companyType: '',
        companyChargePerson: '',
        companyChargePersonIdNumber: '',
        companyContactPerson: '',
        companyContactPhone: '',
        businessTypes: [],

        companyLicenseNumber: '',
        companyLicenseImage: '',
        companyLicenseImageUrl: '',

        companyAddress: '',
        companyRegions: [],

        factoryAddress: '',
        factoryRegions: [],
      },
      editRules: {
        companyName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'}
        ],
        companyType: [
          {required: true, message: '请选择企业类型', trigger: 'change'}
        ],
        companyLicenseNumber: [
          {required: true, message: '请输入营业执照号码', trigger: 'blur'}
        ],
        companyChargePerson: [
          {required: true, message: '请输入负责人姓名', trigger: 'blur'}
        ],
        companyChargePersonIdNumber: [
          {required: true, message: '请输入负责人身份证号码', trigger: 'blur'},
          {validator: validIdcard, trigger: 'blur'}
        ],
        companyContactPerson: [
          {required: true, message: '请输入联系人员姓名', trigger: 'blur'}
        ],
        companyContactPhone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {validator: validPhone, trigger: 'blur'}
        ],

        companyAddress: [
          {required: true, validator: validCompanyAddress, trigger: 'change'}
        ],
      },

      addressOptions: [],

      options: [
        {
          value: '浙江',
          label: '浙江',
          children: [
            {
              value: '杭州',
              label: '杭州',
              children: [
                {
                  value: '西湖',
                  label: '西湖',
                },
              ],
            },
          ],
        },
        {
          value: '江苏',
          label: '江苏',
          children: [
            {
              value: '南京',
              label: '南京',
              children: [
                {
                  value: '中华门',
                  label: '中华门',
                },
              ],
            },
          ],
        },
      ],

      templateList: [],
      
      // 用户上次保存的业务类型及数据
      lastEditBusinessTypes: {},

      // 业务类型
      selectedBusinessTypes: [],
      currentBusinessId: '',

      currentEditBusinessId: '',
      businessTypes: [],

      // 企业用户
      userColumns,
      userData: [],

      // 新增企业用户
      addUservisible: false,
      addUserForm: {
        userNickName: '',
        userPhone: '',
        phoneCode: ''
      },
      addUserRules: {
        userNickName: [
          {required: true, message: '请输入用户昵称', trigger: 'blur'}
        ],
        userPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      },

      // 关联企业用户
      bindUservisible: false,
      bindUserForm: {
        userPhone: '',
        phoneCode: ''
      },
      bindUserRules: {
        userPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      },
      // 获取验证码
      smsCaptcha1: {
        isWaiting: false,
        timer: null,
        second: 60,
        showText: '获取验证码',
      },

      smsCaptcha2: {
        isWaiting: false,
        timer: null,
        second: 60,
        showText: '获取验证码',
      },
      // 查看会员证书
      certificateVisible: false,

    }
  },
  computed: {
		...mapGetters(['adminId'])
	},
  created() {
    this.id = this.$route.query.id
    this.currentPath = this.$route.fullPath
    this.approvedCompanyDetail()
    this.getRegionsData('0')
    this.getEnterpriseUserList()
  },
  methods: {
    // 获取企业详情
    approvedCompanyDetail() {
      this.loading1 = true
      approvedCompanyDetail(this.id).then(res => {
        if (res.code == 200) {
          let companyTypeText = res.data.companyType == 1 ? '工商型': res.data.companyType == 2 ? '个人型' : '-'
          this.detailData = {
            ...res.data,
            companyTypeText,
            companyLicenseImageUrl: buildThumborUrl(res.data.companyLicenseImage)
          }

          // 更新标题
          if (res.data.companyName) {
            this.$store.dispatch('updateTabTitle', {
              force: true,
              path: this.currentPath,
              title: res.data.companyName
            })
          }

          try {
            this.businessTypesArr = this.detailData.businessTypes.split(',')
          } catch (error) {
            this.businessTypesArr = []
          }

          // 获取模板数据
          this.getTemplateList()
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading1 = false
      })
    },
    // 计算企业已选择业务， id => 名称
    calcBusinessTypes() {
      let businessTypesStr = ''
      let selectedBusinessTypes = []

      if (this.detailData.businessTypes && this.templateList.length > 0) {
        let businessTypes = this.detailData.businessTypes.split(',')
        console.log(businessTypes)
        selectedBusinessTypes =  this.templateList.filter(item => businessTypes.indexOf(''+item.id) >= 0 )
        console.log(selectedBusinessTypes)
        businessTypesStr = selectedBusinessTypes.map(item => item.name).join('、') || '-'
      } else {
        selectedBusinessTypes = []
        businessTypesStr = '-'
      }

      this.businessTypesStr = businessTypesStr
      this.selectedBusinessTypes = selectedBusinessTypes
      if (this.selectedBusinessTypes.length > 0) {
        this.currentBusinessId = this.selectedBusinessTypes[0].id
      }
      
    },
    // 查看营业执照大图
    viewBigImage(type) {
      this.imgVisible = true
      this.viewImgUrl = type == 1 ? this.detailData.companyLicenseImageUrl : this.editForm.companyLicenseImageUrl
    },
    // 颁发证书
    issueCertificateHandle() {
      this.$confirm({
        title: '颁发证书',
        content: '是否给该企业颁发产业联盟证书？',
        centered: true,
        onOk: () => {
          console.log('OK')
          this.$showLoading()
          issueCertificate({
            adminId: this.adminId,
            companyId: this.id,
            type: 1 // 证书类型(1-长三角时尚产业联盟)
          }).then(res => {
            console.log(res)
            this.$hideLoading()
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.approvedCompanyDetail()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    // 查看证书
    viewCertificateHandle() {
      this.certificateVisible = true
    },

    // 获取业务类型列表
    getTemplateList() {
      templateList({parentId: '', type: 1}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.templateList = res.data.filter(item => item.status == 1)
          this.calcBusinessTypes()
          this.getCompanyBusinessList()
        } else {
          this.$message.error(res.msg || '获取业务类型出错')
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    
    // 获取地址数据
    getRegionsData(id) {
      return new Promise((resolve, reject) => {
        regionInfo({
          parentId: id
        }).then(res => {
          console.log(res)
          if (res.errorCode == 200) {
            if (id == '0') {
              this.addressOptions = res.data.map(item => ({...item, isLeaf: false}))
            }
            resolve(res.data)
          } else {
            this.$message.error(res.errorMsg || '获取地址信息失败')
            reject(res)
          }
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    // 选择省市区时触发
    loadAddressData(selectedOptions) {
      console.log('targetOption', selectedOptions, targetOption)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      
      this.getRegionsData(targetOption.region_id).then(data => {
        targetOption.loading = false
        if (selectedOptions.length <= 1) {
          targetOption.children = data.map(item => ({...item, isLeaf: false}))
        } else {
          targetOption.children = data
        }
        this.addressOptions = [...this.addressOptions];
      })
    },
    // 选择省市区时触发
    onAddressChange(value) {
      console.log(value)
      console.log(this.editForm.companyRegions)
    },
    // 编辑基础信息
    async editBasicInfoHandle() {
      this.companyEditable = true
      this.editForm.companyName = this.detailData.companyName
      this.editForm.companyType = this.detailData.companyType
      this.editForm.companyLicenseNumber = this.detailData.companyLicenseNumber
      this.editForm.companyChargePerson = this.detailData.companyChargePerson
      this.editForm.companyChargePersonIdNumber = this.detailData.companyChargePersonIdNumber
      this.editForm.companyContactPerson = this.detailData.companyContactPerson
      this.editForm.companyContactPhone = this.detailData.companyContactPhone

      this.editForm.companyLicenseImage = this.detailData.companyLicenseImage
      this.editForm.companyLicenseImageUrl = this.detailData.companyLicenseImageUrl

      let businessTypes = []
      try {
        businessTypes = this.detailData.businessTypes.split(',').map(n => +n)
      } catch (error) {
        console.warn(error)
        businessTypes = []
      }
      this.editForm.businessTypes = businessTypes
      console.log(this.editForm.businessTypes)
      let companyRegions = []
      if (this.detailData.companyProvince) companyRegions[0] = '' + this.detailData.companyProvince
      if (this.detailData.companyCity) companyRegions[1] = '' + this.detailData.companyCity
      if (this.detailData.companyDistrict) companyRegions[2] = '' + this.detailData.companyDistrict
      this.editForm.companyRegions = companyRegions.length > 0 ? [...companyRegions] : null
      this.editForm.companyAddress = this.detailData.companyAddress
      
      let factoryRegions = []
      if (this.detailData.factoryProvince) factoryRegions[0] = '' + this.detailData.factoryProvince
      if (this.detailData.factoryCity) factoryRegions[1] = '' + this.detailData.factoryCity
      if (this.detailData.factoryDistrict) factoryRegions[2] = '' + this.detailData.factoryDistrict
      this.editForm.factoryRegions = factoryRegions.length > 0 ? [...factoryRegions] : null
      this.editForm.factoryAddress = this.detailData.factoryAddress

      console.log(this.editForm)

      let companyRegionsIndexArray = [0, 0, 0]
      if (companyRegions[0]) {
        companyRegionsIndexArray[0] = this.addressOptions.findIndex(region => region.region_id == companyRegions[0])
      }
      if (companyRegions[1]) {
        let addressArr = await this.getRegionsData(companyRegions[0])
        let provinceIndex = companyRegionsIndexArray[0]
        if (provinceIndex >= 0) {
          this.addressOptions[provinceIndex].children = addressArr.map(item => ({...item, isLeaf: false}))
          companyRegionsIndexArray[1] = addressArr.findIndex(region => region.region_id == companyRegions[1])
          console.log(this.addressOptions)
          this.addressOptions = [...this.addressOptions]
        }
      } 
      if (companyRegions[2]) {
        let addressArr = await this.getRegionsData(companyRegions[1])
        let cityIndex = companyRegionsIndexArray[1]
        console.log(companyRegionsIndexArray)
        if (cityIndex >= 0) {
          this.addressOptions[companyRegionsIndexArray[0]].children[cityIndex].children = addressArr
          console.log(this.addressOptions)
          this.addressOptions = [...this.addressOptions]
        }
      }

      let factoryRegionsIndexArray = [0, 0, 0]
      if (factoryRegions[0]) {
        factoryRegionsIndexArray[0] = this.addressOptions.findIndex(region => region.region_id == factoryRegions[0])
      }
      if (factoryRegions[1]) {
        let addressArr = await this.getRegionsData(factoryRegions[0])
        let provinceIndex = factoryRegionsIndexArray[0]
        if (provinceIndex >= 0) {
          this.addressOptions[provinceIndex].children = addressArr.map(item => ({...item, isLeaf: false}))
          factoryRegionsIndexArray[1] = addressArr.findIndex(region => region.region_id == factoryRegions[1])
          console.log(this.addressOptions)
          this.addressOptions = [...this.addressOptions]
        }
      } 
      if (factoryRegions[2]) {
        let addressArr = await this.getRegionsData(factoryRegions[1])
        let cityIndex = factoryRegionsIndexArray[1]
        console.log(factoryRegionsIndexArray)
        if (cityIndex >= 0) {
          this.addressOptions[factoryRegionsIndexArray[0]].children[cityIndex].children = addressArr
          console.log(this.addressOptions)
          this.addressOptions = [...this.addressOptions]
        }
      }
      
    },
    // 上传文件之前
    customBeforeUpload() {
      this.$showLoading()
    },
    // 上传图片
    handleUploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        // this.$message.success(`${info.file.name} file uploaded successfully`);
        // console.log(info)
        let response = info.file.response
        if (response.code == 200) {
          this.$hideLoading()
          this.$message.success('上传成功')
          this.editForm.companyLicenseImage = response.data[0] 
          this.editForm.companyLicenseImageUrl = buildThumborUrl(response.data[0])
        } else {
          this.$message.error(response.msg || '上传失败')
        }
      } else if (info.file.status === 'error') {
        this.$hideLoading()
        this.$message.error('上传失败')
      }
    },

    // 保存基础信息
    saveBasicInfo() {
      console.log(this.editForm)

      this.$refs['editForm'].validate(valid => {
        if (valid) {
          if (this.editForm.companyType == 1 && !this.editForm.companyLicenseImage) {
            this.$message.error('请上传营业执照图片')
            return
          }
          let params = {
            adminId: this.adminId,
            companyId: this.id,

            companyName: this.editForm.companyName,

            companyChargePerson: this.editForm.companyChargePerson,
            companyChargePersonIdNumber: this.editForm.companyChargePersonIdNumber,

            companyContactPerson: this.editForm.companyContactPerson,
            companyContactPhone: this.editForm.companyContactPhone,

            companyType: this.editForm.companyType,
            

            companyProvince: this.editForm.companyRegions && this.editForm.companyRegions[0] || '',
            companyCity: this.editForm.companyRegions && this.editForm.companyRegions[1] || '',
            companyDistrict: this.editForm.companyRegions && this.editForm.companyRegions[2] || '',
            companyAddress: this.editForm.companyAddress,

            factoryProvince: this.editForm.factoryRegions && this.editForm.factoryRegions[0] || '',
            factoryCity: this.editForm.factoryRegions && this.editForm.factoryRegions[1] || '',
            factoryDistrict: this.editForm.factoryRegions && this.editForm.factoryRegions[2] || '',
            factoryAddress: this.editForm.factoryAddress,

            businessTypes: this.editForm.businessTypes,
          }
          if (this.editForm.companyType == 1) {
            params.companyLicenseNumber = this.editForm.companyLicenseNumber
            params.companyLicenseImage = this.editForm.companyLicenseImage // 暂时为空， 待后续上传功能完善再补充
          }
          console.log(params)
          this.$showLoading()
          updateCompanyDetail(params).then(res => {
            this.$hideLoading()
            console.log(res)
            if (res.code == 200) {
              this.companyEditable = false
              this.approvedCompanyDetail()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        }
      })
 
    },

    // 刷新操作
    onRefresh() {
      this.approvedCompanyDetail()
    },

    // 详情 - 获取业务信息
    getCompanyBusinessList() {
      this.loading3 = true
      businessList({businessId: this.id}).then(res => {
        console.log(res)
        if (res.code == 200) {
          // this.detailData.businessTypes
          // this.selectedBusinessTypes
          if (!res.data) {
            console.log(this.selectedBusinessTypes)
            this.selectedBusinessTypes = this.selectedBusinessTypes.map(item => {
              return {
                ...item,
                isEdited: false
              }
            })
          } else if (res.data.content) {
            let content = JSON.parse(res.data.content)
            console.log(this.selectedBusinessTypes)
            console.log(content)
            let contentObj = {}
            content.forEach(item => {
              contentObj[item.id] = item
            })
            this.lastEditBusinessTypes = contentObj
            let firstId = this.selectedBusinessTypes[0].id
            if (contentObj[firstId].content && contentObj[firstId].content.length > 0) {
              this.selectedBusinessTypes[0].isEdited = true
              this.selectedBusinessTypes[0].content = contentObj[firstId].content 

              this.selectedBusinessTypes = [...this.selectedBusinessTypes]
            }
          }
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading3 = false
      })
    },

    // 详情 - 切换业务信息
    businessChange(e) {
      console.log('businessChange', e)
      let id = e.target.value
      let index = this.selectedBusinessTypes.findIndex(item => item.id == id)
      if (this.lastEditBusinessTypes[id] && this.lastEditBusinessTypes[id].content && this.lastEditBusinessTypes[id].content.length > 0) {
        this.selectedBusinessTypes[index].isEdited = true
        this.selectedBusinessTypes[index].content = this.lastEditBusinessTypes[id].content 
      }
    },

    // 编辑业务信息
    editBusinessHandle() {
      this.businessEditable = true
      this.businessTypes = this.templateList.map(item => {
        let isEdited
        if (this.businessTypesArr.indexOf('' + item.id) >= 0) {
          isEdited = true
        } else {
          isEdited = false
        }
        return {
          id: item.id,
          name: item.name,
          hasProps: false,  // 是否配置属性
          isEdited,  // 企业是否填写
          loading: 0, // 0 - 属性未加载/加载失败  1 - 加载中  2 - 加载完成
          content: []
        }
      })

      this.currentEditBusinessId = this.businessTypes[0].id
      this.propertyList(this.currentEditBusinessId, 0, () => {
        console.log('this.businessTypes', this.businessTypes)
      })
    },
    // 获取属性列表
    propertyList(id, modIndex, cb) {
      console.log(this.businessTypesArr)
      if (typeof modIndex !== 'number') {
        modIndex = this.businessTypes.findIndex(item => item.id == id)
      }
      console.log('modIndex:', modIndex, this.businessTypes[modIndex].loading)
      let curMod = this.businessTypes[modIndex]
      console.log(curMod)
      if (curMod && (!curMod.isEdited)) return
      if (curMod && curMod.loading == 2) return
      this.$showLoading()
      propertyList({parentId: id}).then(res => {
        console.log(res)
        this.businessTypes[modIndex].loading = 2
        if (res.code == 200 && res.data && res.data.length > 0) {
          let userProps = {}
          let curProps = this.lastEditBusinessTypes[id]
          if (curProps && curProps.content && Array.isArray(curProps.content)) {
            curProps.content.forEach(item => {
              userProps[item.id] = item
            })
          }
          this.businessTypes[modIndex].hasProps = true
          this.businessTypes[modIndex].content = res.data.map(item => {
            let propertys = JSON.parse(item.propertyContent)
            let dataRange = '', value = '', curUserPropValue = userProps[item.id] && userProps[item.id].value
            if (propertys.controlType == 1) {
              dataRange = []
              value = curUserPropValue || propertys.presetValue || ''
            } else {
              dataRange = propertys.range
            }
            if (propertys.controlType == 2) {
              curUserPropValue ? value = curUserPropValue : value = ''
            }
            if (propertys.controlType == 3) {
              value = curUserPropValue || ''
            }
            if (propertys.controlType == 4) {
              value = curUserPropValue || []
            }
            if (propertys.controlType == 5) {
              value = curUserPropValue || [...dataRange]
            }
            return {
              id: item.id,
              key: item.name,
              value,
              unit: propertys.unit,
              type: propertys.controlType,
              data: dataRange
            }
          })
          if (cb) cb()
        }
      }).catch(err => {
        this.businessTypes[modIndex].loading = 0
        console.log(err)
      }).finally(() => {
        this.$hideLoading()
      })
    },

    // 编辑 - 切换业务信息
    businessEditChange(e) {
      console.log('businessChange', e)
      let id = e.target.value
      this.propertyList(id, null, () => {
        console.log('this.businessTypes', this.businessTypes)
      })
    },
    // 保存业务信息编辑
    editBusinessOk() {
      console.log(this.businessTypes)
      let result = this.businessTypes
        .filter(item => item.isEdited)
        .map(item => {
          let content = item.content.map(c => {
            let showText = c.value
            if (c.type == 1) showText = c.value + c.unit
            if (c.type == 4 || c.type == 5) showText = c.value.join('、')
            return {
              id: c.id,
              key: c.key,
              value: c.value,
              unit: c.unit,
              type: c.type,
              showText
            }
          })
          return {
            id: item.id,
            name: item.name,
            content
          }
        })
      console.log(result)
      this.$showLoading()
      submitBusiness({
        businessId: this.id,
        templatePropertyContent: JSON.stringify(result),
        type: 1
      }).then(res => {
        this.$hideLoading()
        console.log(res)
        if (res.code == 200) {
          this.businessEditable = false
          this.currentBusinessId = this.selectedBusinessTypes[0].id
          this.getCompanyBusinessList()
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.warn(err)
      })
    },

    // 获取企业用户列表
    getEnterpriseUserList() {
      this.loading3 = true
      enterpriseUserList({
        companyId: this.id
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.userData  = res.data.map(item => {
            let associatedApplications = item.associatedApplications.split(',').map(key => {
              return relatedApps[key]
            }).join('、')
            return {
              ...item,
              userNickname: item.userNickname || '-',
              associatedApplications,
            }
          })
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading3 = false
      })
    },

    // 企业用户模块 - 添加企业用户
    addUserOk() {
      this.$refs['addUserForm'].validate(valid => {
        if (valid) {
          console.log('addUserOk:validate-pass')
          this.$showLoading()
          saveEnterpriseUser({
            adminId: this.adminId,
            companyId: this.id,
            userCellphone: this.addUserForm.userPhone.trim(),
            userNickname: this.addUserForm.userNickName.trim(),
            verificationCode: this.addUserForm.phoneCode.trim()
          }).then(res => {
            console.log(res)
            this.$hideLoading()
            if (res.code == 200) {
              this.addUservisible = false
              this.getEnterpriseUserList()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.log(err)
          })
        } else {
          console.log('addUserOk:validate-fail')
        }
      })
    },
    // 企业用户模块 - 绑定企业用户
    bindUserOk() {
      this.$refs['bindUserForm'].validate(valid => {
        if (valid) {
          console.log('bindUserOk:validate-pass')
          this.$showLoading()
          bindRelationship({
            adminId: this.adminId,
            companyId: this.id,
            userCellphone: this.bindUserForm.userPhone.trim(),
            verificationCode: this.bindUserForm.phoneCode.trim()
          }).then(res => {
            console.log(res)
            this.$hideLoading()
            if (res.code == 200) {
              this.bindUservisible = false
              this.getEnterpriseUserList()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.log(err)
          })
        } else {
          console.log('bindUserOk:validate-fail')
        }
      })
    },

    // 获取验证码
    getSmsCode1() {
      if (!this.smsCaptcha1.isWaiting) {
        if (!validateMobile(this.addUserForm.userPhone.trim())) {
          this.$message.error('请输入正确的手机号')
          return
        }

        this.smsCaptcha1.isWaiting = true

        // 重置定时器
        let resetTimer = () => {
          clearInterval(this.smsCaptcha1.timer)
          this.smsCaptcha1.second = 60
          this.smsCaptcha1.showText = '获取验证码'
          this.smsCaptcha1.isWaiting = false
        }

        // 请求接口
        sendEnterpriseUserVerificationCode({cellphone: this.addUserForm.userPhone.trim()}).then(res => {
          // console.log(res)
          if (res.code === 200) {
            this.$message.success('验证码发送成功!')
          } else {
            this.$message.error(res.msg || '网络异常，请稍后重试')
            resetTimer()
          }
        }).catch(err => {
          console.warn(err)
          resetTimer()
        })

        // 倒计时
        clearInterval(this.smsCaptcha1.timer)
        this.smsCaptcha1.showText = this.smsCaptcha1.second + 's'
        this.smsCaptcha1.timer = setInterval(() => {
          if (this.smsCaptcha1.second > 0) {
            this.smsCaptcha1.second --
            this.smsCaptcha1.showText = this.smsCaptcha1.second + 's'
          } else {
            resetTimer()
          }
        }, 1000)
      }
    },

    // 获取验证码
    getSmsCode2() {
      if (!this.smsCaptcha2.isWaiting) {
        if (!validateMobile(this.bindUserForm.userPhone.trim())) {
          this.$message.error('请输入正确的手机号')
          return
        }

        this.smsCaptcha2.isWaiting = true

        // 重置定时器
        let resetTimer = () => {
          clearInterval(this.smsCaptcha2.timer)
          this.smsCaptcha2.second = 60
          this.smsCaptcha2.showText = '获取验证码'
          this.smsCaptcha2.isWaiting = false
        }

        // 请求接口
        sendEnterpriseUserVerificationCode({cellphone: this.bindUserForm.userPhone.trim()}).then(res => {
          // console.log(res)
          if (res.code === 200) {
            this.$message.success('验证码发送成功!')
          } else {
            this.$message.error(res.msg || '网络异常，请稍后重试')
            resetTimer()
          }
        }).catch(err => {
          console.warn(err)
          resetTimer()
        })

        // 倒计时
        clearInterval(this.smsCaptcha2.timer)
        this.smsCaptcha2.showText = this.smsCaptcha2.second + 's'
        this.smsCaptcha2.timer = setInterval(() => {
          if (this.smsCaptcha2.second > 0) {
            this.smsCaptcha2.second --
            this.smsCaptcha2.showText = this.smsCaptcha2.second + 's'
          } else {
            resetTimer()
          }
        }, 1000)
      }
    },

    updateCompanyUser (status, record) {
      let tip = status === 'y' ? '启用' : '禁用';
      this.$confirm({
        title: `确认${tip}该用户(${record.userCellphone})吗？`,
        // content: h => <div>Some descriptions</div>,
        centered: true,
        onOk: () => {
          console.log("OK")
					this.$showLoading()
					updateState({
						userId: record.userId,
						adminId: this.adminId,
						userState: status
					}).then(res => {
						console.log(res)
						if (res.code === 200) {
							this.$message.success(`${tip}成功`)
              this.getEnterpriseUserList()
						} else {
							this.$message.error(res.msg || '操作失败')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.$hideLoading()
					})
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },
    deleteCompanyUser (record) {
      this.$confirm({
        title: `确认删除该用户(${record.userCellphone})吗？`,
        // content: h => <div>Some descriptions</div>,
        centered: true,
        onOk: () => {
          console.log("OK")
					this.$showLoading()
					dismissRelationship({
						userId: record.userId,
						adminId: this.adminId,
            companyId: this.id
					}).then(res => {
						console.log(res)
						if (res.code === 200) {
							this.$message.success(`删除成功`)
              this.getEnterpriseUserList()
						} else {
							this.$message.error(res.msg || '操作失败')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.$hideLoading()
					})
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    } 

  }
}
</script>

<style lang="less">
.address-cascader {
  border-radius: 2px 0 0 2px;
  .ant-input {
    border-right-color: #eee;
    border-radius: 2px 0 0 2px;
  }
}
</style>