import request from '@/utils/request-dw'

/*
    获取省市区列表  
    params: 
    {
      parentId: '0'
    }
*/
export function regionInfo(parameter) {
    return request({
        url: '/globalRegionInfo/regionInfo',
        method: 'post',
        data: parameter,
    })
}