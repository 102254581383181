import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.system_base_url)


// 系统数据 - 接口列表
const apis = {
  // 数据维护列表
  dataCategoryList: '/yijiajia-data-category/page',
  // 数据维护-新增
  dataCategoryAdd: '/yijiajia-data-category/save',
  // 数据维护-子项目列表
  dataItemList: '/yijiajia-data-category/list',
  // 数据维护-修改
  dataCategoryUpdate: '/yijiajia-data-category/update',
  // 数据维护-删除
  dataCategoryRemove: '/yijiajia-data-category/remove',
  // 数据维护-排序
  dataCategorySort: '/yijiajia-data-category/sort',


  // 数据模板 - 分组列表
  groupList: '/yijiajia-data-template/groupList',
  // 数据模板 - 属性列表
  propertyList: '/yijiajia-data-template/propertyList',
  // 数据模板 - 属性排序
  propertySort: '/yijiajia-data-template/propertySort',
  // 数据模板 - 删除分组
  removeGroup: '/yijiajia-data-template/removeGroup',
  // 数据模板 - 删除属性
  removeProperty: '/yijiajia-data-template/removeProperty',
  // 数据模板 - 删除模板
  removeTemplate: '/yijiajia-data-template/removeTemplate',
  // 数据模板 - 新增分组
  saveGroup: '/yijiajia-data-template/saveGroup',
  // 数据模板 - 新增属性
  saveProperty: '/yijiajia-data-template/saveProperty',
  // 数据模板 - 新增模板
  saveTemplate: '/yijiajia-data-template/saveTemplate',
  // 数据模板 - 模板列表
  templateList: '/yijiajia-data-template/templateList',
  // 数据模板 - 修改分组
  updateGroup: '/yijiajia-data-template/updateGroup',
  // 数据模板 - 修改属性
  updateProperty: '/yijiajia-data-template/updateProperty',
  // 数据模板 - 修改模板
  updateTemplate: '/yijiajia-data-template/updateTemplate',
  // 数据模板 - 通过业务获取所选模板属性
  businessList: '/yijiajia-template-property-relation/getByBusinessId',
  // 数据维护：新增或修改企业所选模板属性
  submitBusiness: '/yijiajia-template-property-relation/submit',
}


/**
 * 数据维护列表 
 */
export function dataCategoryList (params) {
  return request({
    url: apis.dataCategoryList,
    method: 'get',
    params
  })
}

/**
 * 数据维护-子项目列表
 */
export function dataItemList (params) {
  return request({
    url: apis.dataItemList,
    method: 'get',
    params
  })
}

/**
 * 数据维护-新增
 */
export function dataCategoryAdd (data) {
  return request({
    url: apis.dataCategoryAdd,
    method: 'post',
    data
  })
}

/**
 * 数据维护-修改
 */
export function dataCategoryUpdate (data) {
  return request({
    url: apis.dataCategoryUpdate,
    method: 'post',
    data
  })
}

/**
 * 数据维护-删除
 */
export function dataCategoryRemove (data) {
  return request({
    url: apis.dataCategoryRemove,
    method: 'post',
    data
  })
}

/**
 * 数据维护-排序
 */
export function dataCategorySort (data) {
  return request({
    url: apis.dataCategorySort,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 数据模板 - 分组列表
export function groupList (parameter) {
  return request({
    url: apis.groupList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 属性列表
export function propertyList (parameter) {
  return request({
    url: apis.propertyList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 属性排序
export function propertySort (parameter) {
  return request({
    url: apis.propertySort,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 数据模板 - 删除分组
export function removeGroup (parameter) {
  return request({
    url: apis.removeGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 删除属性
export function removeProperty (parameter) {
  return request({
    url: apis.removeProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 删除模板
export function removeTemplate (parameter) {
  return request({
    url: apis.removeTemplate,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增分组
export function saveGroup (parameter) {
  return request({
    url: apis.saveGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增属性
export function saveProperty (parameter) {
  return request({
    url: apis.saveProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增模板
export function saveTemplate (parameter) {
  return request({
    url: apis.saveTemplate,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 模板列表
export function templateList (parameter) {
  return request({
    url: apis.templateList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 修改分组
export function updateGroup (parameter) {
  return request({
    url: apis.updateGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 修改属性
export function updateProperty (parameter) {
  return request({
    url: apis.updateProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 修改模板
export function updateTemplate (parameter) {
  return request({
    url: apis.updateTemplate,
    method: 'post',
    data: parameter
  })
}

// 数据模板 - 通过业务获取所选模板属性
export function businessList (parameter) {
  return request({
    url: apis.businessList,
    method: 'get',
    params: parameter
  })
}

// 数据维护：新增或修改企业所选模板属性
export function submitBusiness (parameter) {
  return request({
    url: apis.submitBusiness,
    method: 'post',
    data: parameter
  })
}










